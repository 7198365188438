<template>
<!--begin::Container-->
<div class="container">
    <transition name="fade" mode="out-in">
        <!--begin::Modal-->
        <div class="modal" v-if="showTradeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalSizeXl">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">

                        <h5 class="modal-title" id="exampleModalLabel">
                            <div class="d-flex align-items-center">
                                <div class="symbol symbol-50 symbol-light mr-4">
                                    <span class="symbol-label">
                                        <img :src="tradeModal.Signal.SymbolLogoLink != '' ? tradeModal.Signal.SymbolLogoLink : '/assets/media/logos/LOGOMINI.png'" class="h-75" alt="">
                                    </span>
                                </div>
                                <div>
                                    <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{tradeModal.Signal.Symbol}}-{{tradeModal.CurrencyAgainst}}</a>
                                    <span class="text-muted font-weight-bold d-block">{{tradeModal.ExecutedQuantity}} {{tradeModal.Signal.Symbol}} ({{tradeModal.Price}})</span>
                                </div>
                            </div>
                        </h5>
                        <button @click="showTradeModal = false" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="tradeHistoryChart"></div>
                    </div>
                    <div class="modal-footer">
                        <button @click="closeOrder" type="button" class="btn btn-danger font-weight-bold" v-show="tradeModal.Category == 'BUY' && tradeModal.Status == 'FILLED'">Close position now</button>
                        <button @click="showTradeModal = false" type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Go back</button>
                    </div>
                </div>
            </div>
        </div>
        <!--end::Modal-->
    </transition>
    <!--begin::Dashboard-->
    <!--begin::Row-->
    <div class="row">
        <div v-show="false" class="col-xl-3">
            <div class="card card-custom gutter-b" style="height:200px; max-height:200px;">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Long term</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">1-20 days long trades</span>
                        <div class="row">

                        </div>

                    </h3>
                    <div class="card-toolbar">

                    </div>
                </div>
            </div>
            <div class="card card-custom gutter-b" style="height:200px; max-height:200px;">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Short term</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">0-3 days long trades</span>
                    </h3>
                    <div class="card-toolbar">

                    </div>
                </div>
            </div>
            <div class="card card-custom gutter-b" style="height:200px; max-height:200px;">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Flash pumps</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">Instant take profit</span>
                    </h3>
                    <div class="card-toolbar">

                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-12">
            <!--begin::Base Table Widget 6-->
            <div class="card card-custom gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Latests signals</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">Signals history</span>
                    </h3>
                    <div class="card-toolbar">
                        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                            <li class="nav-item">
                                <a class="nav-link py-2 px-4" :class="{'active': period == 'month'}" @click="period = 'month'" data-toggle="tab" href="#kt_tab_pane_6_1">Month</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link py-2 px-4" :class="{'active': period == 'week'}" @click="period = 'week'" data-toggle="tab" href="#kt_tab_pane_6_2">Week</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link py-2 px-4" :class="{'active': period == 'day'}" @click="period = 'day'" data-toggle="tab" href="#kt_tab_pane_6_3">Day</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-2 pb-0 mt-n3">
                    <div class="tab-content mt-5" id="myTabTables6">

                        <!--begin::Tap pane-->
                        <div class="tab-pane fade active show" id="kt_tab_pane_6_3" role="tabpanel" aria-labelledby="kt_tab_pane_6_3">
                            <div class="table-responsive" style="min-height: 300px;">
                                <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                                    <thead>
                                        <tr class="text-left">
                                            <th style="min-width: 80px" class="pl-7">
                                                COIN
                                            </th>
                                            <th style="min-width: 100px">DESCRIPTION</th>

                                            <th style="min-width: 50px"></th>
                                            <th style="min-width: 50px"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="signals.length == 0">
                                            <td colspan="3">
                                                <center class="mt-10">
                                                    <img src="assets/media/svg/illustrations/nodata.svg" style="height: 50px;" />
                                                    <h3 class="mt-5"> There are no signals for the selected period.</h3>

                                                </center>
                                            </td>
                                        </tr>
                                        <tr v-for="signal in signals.filter(function(s){ return (new Date(s.CreatedAt)).getTime() >= minDate.getTime(); })" :key="signal.ID">
                                            <td class="pl-0 py-2">
                                                <div class="d-flex align-items-center">
                                                    <div class="symbol symbol-50 symbol-light mr-4">
                                                        <span class="symbol-label">
                                                            <img :src="signal.SymbolLogoLink != '' ? signal.SymbolLogoLink : '/assets/media/logos/LOGOMINI.png'" class="h-75" alt="">
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{signal.Symbol}}</a>
                                                        <span class="text-muted font-weight-bold d-block">{{(new Date(signal.CreatedAt)).toLocaleDateString()}} {{(new Date(signal.CreatedAt)).toLocaleTimeString()}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{signal.Title}}</span>
                                                <span class="text-muted font-weight-bold">{{signal.Type}}</span>
                                            </td>
                                            <td class="pr-0">
                                                <a v-if="(Date.now() - (new Date(signal.CreatedAt)).getTime()) < 600000" class="btn btn-light-primary font-weight-bolder font-size-sm"> BUY IT - TIME LEFT: {{new Date(600000-(Date.now() - (new Date(signal.CreatedAt)).getTime())).toISOString().substr(11, 8)}}</a>
                                            </td>

                                            <td class="pr-0 text-right">
                                                <a v-if="signal.Exchange == 'Binance'" target="_blank" :href="'https://www.binance.com/en/trade/' + signal.Symbol.replace('USDT', '') + '_USDT?layout=pro'" class="btn btn-light-primary font-weight-bolder font-size-sm" style="color: rgb(240, 185, 11); background-color: rgb(240 185 11 / 22%);"> <img src='assets/media/binance.png' style="max-width:15px;"> Binance</a>
                                                <a v-if="signal.Exchange == 'Coinbase Pro'" target="_blank" :href="'https://pro.coinbase.com/trade' + signal.Symbol.replace('USDT', '') + '-USDT'" class="btn btn-light-primary font-weight-bolder font-size-sm" style="color: #22af91; background-color: #4dd0b357; margin-left:8px;"> <img src='assets/media/kucoin.png' style="max-width:15px;"> Coinbase Pro</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--begin::Table-->
                            <!--end::Table-->
                        </div>
                        <!--end::Tap pane-->
                    </div>
                </div>
                <!--end::Body-->
                <div class="card-footer row row-marginless align-items-center flex-wrap py-5 h-auto px-0">

                    <div class="col-12 col-12-6 col-xxl-12 order-2 order-xxl-12 d-flex align-items-center justify-content-sm-end text-right my-0">
                        <div class="d-flex align-items-center mr-2" data-toggle="tooltip" title="" data-original-title="Records per page">
                            <div class="spinner spinner-primary spinner-right">
                                <span class="pr-15">Last update {{((now - lastUpdate)/1000).toFixed(2)}} seconds ago..</span>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!--end::Base Table Widget 6-->
        </div>
    </div>
    <!--end::Row-->

    <div class="row">
        <div class="col-xl-6">
            <!--begin::Base Table Widget 6-->
            <div class="card card-custom gutter-b" style="min-height: 500px;">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Latests orders</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">Your transactions performed on your account</span>
                    </h3>
                    <!--<div class="card-toolbar">
                        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                            <li class="nav-item">
                                <a class="nav-link py-2 px-4" :class="{'active': period == 'month'}" @click="period = 'month'" data-toggle="tab" href="#kt_tab_pane_6_1">Month</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link py-2 px-4" :class="{'active': period == 'week'}" @click="period = 'week'" data-toggle="tab" href="#kt_tab_pane_6_2">Week</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link py-2 px-4" :class="{'active': period == 'day'}" @click="period = 'day'" data-toggle="tab" href="#kt_tab_pane_6_3">Day</a>
                            </li>
                        </ul>
                    </div>-->
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-2 pb-0 mt-n3">
                    <div class="tab-content mt-5" id="myTabTables6">

                        <!--begin::Tap pane-->
                        <div class="tab-pane fade active show" id="kt_tab_pane_6_3" role="tabpanel" aria-labelledby="kt_tab_pane_6_3">
                            <div class="table-responsive" style="min-height: 300px;">
                                <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                                    <thead>
                                        <tr class="text-left">
                                            <th style="min-width: 50px" class="pl-7">
                                                BinanceID
                                            </th>
                                            <th style="min-width: 50px">DESCRIPTION</th>
                                            <th style="min-width: 50px">TYPE</th>
                                            <th style="min-width: 50px"></th>
                                            <th style="min-width: 50px"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!--<tr v-if="$parent.Membership != 'VIP' && $parent.Membership != 'Owner'">
                                        <td colspan="4">
                                            <center class="mt-10">
                                                <img src="assets/media/svg/illustrations/upgrade.svg" style="height: 50px;" />
                                                <h3 class="mt-5"> You must upgrade your plan to get access to this feature.</h3>

                                            </center>
                                        </td>
                                    </tr>-->
                                        <tr v-if="orders.length == 0">
                                            <td colspan="4">
                                                <center class="mt-10">
                                                    <img src="assets/media/svg/illustrations/nodata.svg" style="height: 50px;" />
                                                    <h3 class="mt-5"> There are no orders yet.</h3>

                                                </center>
                                            </td>
                                        </tr>
                                        <tr v-for="order in orders" :key="order.ID">
                                            <td class="pl-6">
                                                
                                                <a style="" class="btn  font-weight-bolder font-size-sm" :class="{'btn-light-primary': order.Status == 'FILLED', 'btn-light-warning': order.Status == 'PARTIALLY_FILLED', 'btn-light-danger': order.Status != 'PARTIALLY_FILLED' && order.Status != 'FILLED'}">{{order.Status}} <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{order.BinanceId}}</span></a>
                                            </td>
                                            <td class="pl-0 py-2">

                                                <div>
                                                    <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{order.Signal.Symbol}}-{{order.CurrencyAgainst}}</a>
                                                    <span class="text-muted font-weight-bold d-block">{{order.ExecutedQuantity}} {{order.Signal.Symbol}} ({{order.Price}})</span>
                                                </div>

                                            </td>
                                            <td>
                                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{order.Type}}</span>
                                                <span class="text-muted font-weight-bold">{{(new Date(order.CreatedAt)).toLocaleDateString()}} {{(new Date(order.CreatedAt)).toLocaleTimeString()}}</span>
                                            </td>
                                            
                                            <td class="pr-0 text-right">
                                                <a style="width:100%;" class="btn  font-weight-bolder font-size-sm mr-3" :class="{'btn-primary': order.Category == 'SELL' && order.TradeResult > 0, 'btn-info': order.Category == 'BUY', 'btn-danger': order.Category == 'SELL' && order.TradeResult <= 0}">{{order.Category}}<br v-if="order.Category == 'SELL'" /><small v-if="order.Category == 'SELL'">{{order.TradeResult.toFixed(2)}}%</small></a>
                                            </td>

                                            <td class="pr-0 text-right">
                                                
                                                <a @click="showTradeModal = true; tradeModal = order;" class="btn btn-transparent text-primary date-sel-active font-weight-bolder font-size-sm">Details</a>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--begin::Table-->
                            <!--end::Table-->
                        </div>
                        <!--end::Tap pane-->
                    </div>
                </div>
                <!--end::Body-->
                <div class="card-footer row row-marginless align-items-center flex-wrap py-5 h-auto px-0">

                    <div class="d-flex justify-content-between align-items-center flex-wrap col-12 px-10">
                        <div class="d-flex flex-wrap py-2 mr-3">

                            <a v-if="page > 1" @click="page--" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                                <i class="ki ki-bold-arrow-back icon-xs"></i>
                            </a>

                            <a v-if="page < Math.ceil(total_orders/per_page)" @click="page++" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                                <i class="ki ki-bold-arrow-next icon-xs"></i>
                            </a>
                        </div>
                        <div class="d-flex align-items-center py-3">
                            <div class="d-flex align-items-center">
                                <div class="mr-2 text-muted">Loading...</div>
                                <div class="spinner spinner-primary mr-10"></div>
                            </div>
                            <select v-model="per_page" class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary" style="width: 75px;">
                                <option :value="5">5</option>
                                <option :value="10">10</option>
                                <option :value="20">20</option>
                            </select>
                            <span class="text-muted">Showing records from {{((page-1)*per_page)+1}} to {{(page)*per_page}} of {{total_orders}}</span>
                        </div>
                    </div>
                </div>

            </div>
            <!--end::Base Table Widget 6-->
        </div>


        <div class="col-xl-6">
            <!--begin::Base Table Widget 6-->
            <div class="card card-custom gutter-b" style="min-height: 500px;">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Top trades</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">Most worth global trades.</span>
                    </h3>
                    <!--<div class="card-toolbar">
                        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                            <li class="nav-item">
                                <a class="nav-link py-2 px-4" :class="{'active': period == 'month'}" @click="period = 'month'" data-toggle="tab" href="#kt_tab_pane_6_1">Month</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link py-2 px-4" :class="{'active': period == 'week'}" @click="period = 'week'" data-toggle="tab" href="#kt_tab_pane_6_2">Week</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link py-2 px-4" :class="{'active': period == 'day'}" @click="period = 'day'" data-toggle="tab" href="#kt_tab_pane_6_3">Day</a>
                            </li>
                        </ul>
                    </div>-->
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-2 pb-0 mt-n3">
                    <div class="tab-content mt-5" id="myTabTables6">

                        <!--begin::Tap pane-->
                        <div class="tab-pane fade active show" id="kt_tab_pane_6_3" role="tabpanel" aria-labelledby="kt_tab_pane_6_3">
                            <div class="table-responsive" style="min-height: 300px;">
                                <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                                    <thead>
                                        <tr class="text-left">
                                            <th style="width: 200px" class="pl-3">
                                                Coin
                                            </th>
                                            <th style="min-width: 100px">Details</th>
                                            <th style="width: 100px">Profit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!--<tr v-if="$parent.Membership != 'VIP' && $parent.Membership != 'Owner'">
                                        <td colspan="4">
                                            <center class="mt-10">
                                                <img src="assets/media/svg/illustrations/upgrade.svg" style="height: 50px;" />
                                                <h3 class="mt-5"> You must upgrade your plan to get access to this feature.</h3>

                                            </center>
                                        </td>
                                    </tr>-->
                                        <tr v-if="top_trades == null || top_trades.length == 0">
                                            <td colspan="4">
                                                <center class="mt-10">
                                                    <img src="assets/media/svg/illustrations/nodata.svg" style="height: 50px;" />
                                                    <h3 class="mt-5"> There are no orders yet.</h3>

                                                </center>
                                            </td>
                                        </tr>
                                        <tr v-for="trade in top_trades" :key="trade.ID">
                                            <td class="pl-0 py-2">
                                                <div class="d-flex align-items-center">
                                                    <div class="symbol symbol-50 symbol-light mr-4">
                                                        <span class="symbol-label">
                                                            <img :src="trade.Signal.SymbolLogoLink != '' ? trade.Signal.SymbolLogoLink : '/assets/media/logos/LOGOMINI.png'" class="h-75" alt="">
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{trade.Signal.Symbol}}-{{trade.Order.CurrencyAgainst}}</a>
                                                        <span class="text-muted font-weight-bold d-block">{{trade.Signal.Symbol}} ({{trade.Order.Price}})</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="pl-3">
                                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{trade.User.Email}}</span>{{(new Date(trade.Order.CreatedAt)).toLocaleDateString()}} {{(new Date(trade.Order.CreatedAt)).toLocaleTimeString()}}
                                            </td>
                                            <td class="">
                                                <a class="btn  font-weight-bolder font-size-sm btn-primary">{{trade.Order.TradeResult.toFixed(2)}}%</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--begin::Table-->
                            <!--end::Table-->
                        </div>
                        <!--end::Tap pane-->
                    </div>
                </div>
                <!--end::Body-->

            </div>
            <!--end::Base Table Widget 6-->
        </div>

    </div>
    <!--end::Row-->

    <div class="row">
        
    </div>
    <!--end::Row-->

    <!--end::Dashboard-->
</div>
<!--end::Container-->
</template>

<script>
import Binance from 'binance-api-node'
import Maurer from './../../components/Maurer.vue';
// Shared Colors Definition
const primary = '#33e47f';
const success = '#027E75';
const info = '#11998F';
const warning = '#11998F';
const danger = '#38ef7d';
export default {
    methods: {

        closeOrder(){
            var vm = this;
            Maurer.api.order.sell({
                Id: vm.tradeModal.Id
            }, function (d) {
                Maurer.api.success(d);
                vm.showTradeModal = false;

            }, function (d) {
                Maurer.api.error(d);
            });
        },

        async connectBinance() {
            var vm = this;
            const binanceClient = Binance()
            const pair = vm.tradeModal.Signal.Symbol + vm.tradeModal.CurrencyAgainst
            this.binanceChartData = await binanceClient.candles({
                symbol: pair,
                interval: '1m',
                limit: 60
            }) // 24h dett 5min
            this.binanceConnectionCleaner = binanceClient.ws.candles(pair, '1m', candle => {
                console.log(candle)
                if (vm.binanceChartData.filter(function (c) {
                        return c.closeTime == candle.closeTime;
                    }).length == 0) {
                    console.log('NUOVA CANDELA')
                    vm.binanceChartData.push(candle)
                } else {
                    for (var a = 0; a < vm.binanceChartData.length; a++) {
                        if (vm.binanceChartData[a].closeTime == candle.closeTime) {
                            console.log(a)
                            //vm.binanceChartData[a] = candle;
                        }
                    }
                    console.log('VECCHIA CANDELA AGGIORNATA')
                }
                /* NOT WORKING
                this.tradeHistoryChart.updateOptions({
                    xaxis: {
                        categories: vm.binanceChartData.map(function (a) {
                            return a.closeTime
                        }),
                    },

                    series: {
                        name: 'Value',
                        type: 'line',
                        data: vm.binanceChartData.map(function (a) {
                            return parseFloat(a.close).toFixed(2);
                        })
                    }
                })
                */
            })
            this.renderTradeHistoryChart(vm.binanceChartData)
        },

        disconnectBinance() {
            this.binanceConnectionCleaner()
            if (this.tradeHistoryChart != null) {
                this.tradeHistoryChart.destroy()
            }
        },

        renderTradeHistoryChart(HistoryData) {
            var vm = this;
            if (HistoryData == null) {
                return;
            }

            var options2 = {
                colors: [primary, danger, info],
                series: [{
                    name: 'Value',
                    type: 'line',
                    data: HistoryData.map(function (a) {
                        return parseFloat(a.close).toFixed(2);
                    })
                }],
                chart: {
                    height: 350,
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: [2, 1, 4]
                },

                grid: {
                    borderColor: '#b5b5c3',
                },

                xaxis: {
                    type: 'datetime',
                    categories: HistoryData.map(function (a) {
                        return a.closeTime
                    }),
                    labels: {
                        style: {
                            colors: '#b5b5c3',
                        },
                        formatter: function (value, timestamp, opts) {
                            return (new Date(timestamp)).toLocaleDateString() + ' ' + (new Date(timestamp)).toLocaleTimeString()
                        }
                    },

                },
                yaxis: [{
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: primary
                        },
                        labels: {
                            style: {
                                colors: '#b5b5c3',
                            }
                        },
                        title: {
                            text: vm.tradeModal.CurrencyAgainst,
                            style: {
                                color: '#b5b5c3',
                            }
                        },
                        tooltip: {
                            enabled: true
                        }
                    },

                ],
                tooltip: {
                    fixed: {
                        enabled: true,
                        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                        offsetY: 30,
                        offsetX: 60
                    },
                    theme: 'dark',
                    followCursor: true
                },
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40,
                    labels: {
                        colors: ['#ffffff']
                    }
                },
            };

            this.tradeHistoryChart = new window.ApexCharts(document.querySelector('#tradeHistoryChart'), options2);
            this.tradeHistoryChart.render();
        },

        init() {
            var vm = this;

            Maurer.api.order.top(function (d) {
                //console.log(d)
                vm.top_trades = d.orders;
                //vm.top_trades = d.orders.slice().reverse();
            }, function (d) {
                Maurer.api.error(d);
            });

            if (vm.interval != null) {
                clearInterval(vm.interval);
            }
            vm.interval = setInterval(function () {
                if (vm.$route.path == '/home') {
                    if (vm.now - vm.lastUpdate > 3000) {
                        vm.refresh();
                    } else {
                        vm.now = Date.now();
                    }
                }

            }, 100)
        },

        refresh() {
            var vm = this;
            vm.lastUpdate = Date.now();
            vm.now = Date.now();
            
            Maurer.api.signal.get(function (d) {
                if (d.signals.length > vm.signals.length && vm.period != '') {
                    Maurer.api.notify_success('New signal!', 'Check the signals list.');
                }
                vm.signals = d.signals;
                if (vm.period == '') {
                    vm.period = 'week';
                }

            }, function (d) {
                Maurer.api.error(d);
            });

            Maurer.api.order.get({
                From: (vm.page - 1) * vm.per_page,
                To: vm.page * vm.per_page
            }, function (d) {
                vm.orders = d.orders;
                vm.total_orders = d.total;

            }, function (d) {
                Maurer.api.error(d);
            });
        }
    },

    mounted() {
        var vm = this;
        vm.init();
    },

    watch: {
        period(n, o) {
            var vm = this;
            if (n == 'month') {
                vm.minDate = new Date();
                vm.minDate.setDate(vm.minDate.getDate() - 30);
            } else if (n == 'week') {
                vm.minDate = new Date();
                vm.minDate.setDate(vm.minDate.getDate() - 7);
            } else if (n == 'day') {
                vm.minDate = new Date();
                vm.minDate.setDate(vm.minDate.getDate() - 1);
            }

            console.log(vm.minDate)
        },

        per_page() {
            var vm = this;
            vm.page = 1;
        },

        page() {
            var vm = this;
            Maurer.api.order.get({
                From: (vm.page - 1) * vm.per_page,
                To: vm.page * vm.per_page
            }, function (d) {
                vm.orders = d.orders;
                vm.total_orders = d.total;

            }, function (d) {
                Maurer.api.error(d);
            });
        },

        showTradeModal(n) {
            if (n) {
                this.connectBinance()
            } else {
                this.disconnectBinance()
            }
        }
    },

    data() {
        return {
            period: '',
            signals: [],
            orders: [],
            minDate: new Date(),
            interval: null,
            lastUpdate: 0,
            now: 0,
            per_page: 10,
            page: 1,
            total_orders: 1,
            max_page_ui: 1,
            top_trades: [],
            showTradeModal: false,
            tradeModal: null,
            binanceConnectionCleaner: null,
            binanceChartData: [],
            tradeHistoryChart: null
        }
    }
}
</script>

<style>
.modal {
    display: block;
}

.modal-content {
    background-color: #2b333e;
    color: #fff !important;
}

.modal-title {
    font-weight: 500;
    color: #33e47f !important;
}

.modal-footer {
    border-top: 1px solid #33e47f;
}

.modal-header {
    border-bottom: 1px solid #33e47f;
}
</style>
